export const BusinessStrategy  = {
    "id": 1,
    "title": "Business Strategy & Solutions",
    
};

export const ITTitle  = {
    "id": 1,
    "title": "IT Consulting",
    
};
export const HRTitle  = {
    "id": 1,
    "title": "Human Resources Services",
    
};

export const PSTitle  = {
    "id": 1,
    "title": "Procurement & Supply Chain",
    
};
export const CTTitle  = {
    "id": 1,
    "title": "Corporate Training",
    
};
export const SATitle  = {
    "id": 1,
    "title": "Staffing Augmentation",
    
};
export const OTitle  = {
    "id": 1,
    "title": " Outsourcing",
    
};

export const BITitle  = {
    "id": 1,
    "title": " Business Intelligence",
    
};
export const DAMTitle  = {
    "id": 1,
    "title": "Digital Asset Management",
    
};
export const ARTitle  = {
    "id": 1,
    "title": " AR / VR",
    
};
export const M365Title  = {
    "id": 1,
    "title": "   Microsoft 365",
    
};


