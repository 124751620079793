import React from 'react';
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import { M365BD } from '../Data/BreadcrumbData';
import { M365AD } from '../Data/ArticleData';
import { M365Title } from '../Data/LeftColmnsData';
import "../scss/inner-page.scss";

const LeftColumn = ({ title }) => {
    return (
        <>
            <div className="call-do-action">
                <div className="top-link">
                    <a href="#">{title}<i className="bi bi-arrow-right-short"></i></a>
                </div>
                <div className="single-pack d-flex">
                    <div className="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/download-file.png'} alt="ValueSoft_services" />
                    </div>
                    <div className="pack-content">
                        <div className="pack-title"><h5>M365 Case Study</h5></div>

                        <div className="get-pack"><a href="/images/VSI_M365_CASE_STUDIES.pdf">Download PDF</a></div>
                    </div>
                </div>

                <div className="single-pack d-flex">
                    <div className="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/value.png'} alt="ValueSoft_services" />
                    </div>
                    <div className="pack-content">
                        <div className="pack-title"><h5>SALES QUERIES</h5></div>
                        <div className="get-pack"><a href="mailto:sales@valuesoftinfo.com">Mail Us..!</a></div>
                    </div>
                </div>

                <div className="cda-content text-left">
                    <h4>Contact Us</h4>
                    <h3><a href="mailto:sales@valuesoftinfo.com"> <i className="bi bi-envelope-open"></i> sales@valuesoftinfo.com </a></h3>
                    <h3><a href="tel:080-43729615"><i className="bi bi-telephone-plus"></i> 080-43729615 </a></h3>
                    <h3><i className="bi bi-geo-alt"></i> USA(Irving), UAE(Dubai), Malaysia(kuala Lumpur), India(Bengaluru, Hyderabad, Pune)</h3>
                    <ul className="social">
                        <li className="active"><a href="https://www.facebook.com/valuesoftinfo/" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ValueSoft_info" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/valuesoftinfo" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

const M365Page = () => {
    return (
        <>
            <div>
                <Breadcrumb {...M365BD} />
                <div className="single-service pt-75 pb-75">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <LeftColumn {...M365Title} />
                            </div>
                            <div className="col-lg-8">
                                <Article {...M365AD} />
                                <div className="ap-section-content">
                                    <div className="overview-content">
                                        <h2 className="overview-title">Our Microsoft 365 Services:</h2>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ul className="list-style-bullet">
                                                    <li>Microsoft 365 Setup & Migration: Transition to Microsoft 365 with ease! Our experts handle everything from setup to migration, ensuring a smooth, hassle-free switch with zero downtime.</li>
                                                    <li>Customization & Integration: Tailor Microsoft 365 to fit your business needs by integrating it with your existing tools and workflows. We customize everything from SharePoint to Teams, so your team gets the most out of the platform.</li>
                                                    <li>Security & Compliance Solutions: Protect your data with Microsoft 365’s robust security features. We help you configure policies, ensure compliance with industry standards, and keep your sensitive information safe.</li>
                                                    <li>Training & Support: Empower your team with expert training and ongoing support. We provide hands-on sessions to get your staff up to speed with Microsoft 365’s full range of features, maximizing productivity.</li>
                                                    <li>Managed Microsoft 365 Services: Let us manage your Microsoft 365 environment for you. From regular updates to user management and troubleshooting, our team ensures everything runs smoothly while you focus on your business.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                <div className="overview-thumb mb-30">
                                                    <img src="/images/innerpage/ai-2.png" alt="AI" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="ap-content-inner d-flex">
                                                    <div className="ap-content-icon">
                                                        <i className="bi bi-check-circle"></i>
                                                    </div>
                                                    <div className="ap-content-text ml-10">
                                                        Why Choose Valuesoft Info?
                                                    </div>
                                                </div>
                                                <div className="ap-content-inner d-flex mt-20">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <ul className="list-style-bullet">
                                                                <li>Seamless Implementation: Experience a smooth transition with zero disruption to your daily operations.</li>
                                                                <li>Expert Guidance: Leverage our in-depth Microsoft 365 expertise to customize and optimize the platform for your business.</li>
                                                                <li>Ongoing Support: Our dedicated team provides continuous support, ensuring your Microsoft 365 environment is always up and running.</li>
                                                                <li>Increased Collaboration & Productivity: Get the most out of Microsoft’s tools for communication, collaboration, and document management.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="pt-15 pb-25">
                                            Supercharge your workplace with Valuesoft Info’s Microsoft 365 Services—where productivity meets simplicity!
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default M365Page;
