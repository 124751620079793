import React from "react";
import Breadcrumb from "./Breadcrumb";

import '../scss/our-company.scss';
import { AboutNav } from "../Data/BreadcrumbData";


const Company = () => {
  return (
    <div >
     <Breadcrumb  {...AboutNav} />
      <div className="about-section style-5 pt-50 pb-75">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6">
              <div className="about-content-container">
                <div className="section-head-container">
                  <div className="section-head">
                    

                    <h3>Helping Clients Create the content <span className="primary-orange ">  Future </span></h3>
                   
                    <div className="block-text">
                      <p>Valuesoft Info  is a global consulting and technology Services Company dedicated to accelerating the client's business with Microsoft technologies and ERP by delivering effective services and solutions.</p>
                    </div>
                    <p> We provide effective consulting services to various companies across the globe in the aspect of database administration, system administration, and custom software development.</p>
                  </div>
                </div>
                <div className="common-video style-5 mt-5">
                  <div className="video-icon">
                    <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://youtu.be/4C1UCWITcY8"><i className="bi bi-play-circle"></i></a>
                    <span>How IT Work </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="about-thumb-section">
                <div className="about-single-thumb">
                  <img src={process.env.PUBLIC_URL + '/images/ab2.jpg'} alt="ValueSoft_social_media" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-section pt-75 pb-45">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="service-single-items d-flex mb-30">
                <div className="service-number">
                  <span>01</span>
                </div>
                <div className="service-content">
                  <div className="service-title">
                    <h2>Who we are.</h2>
                  </div>
                  <div className="service-text">
                    <p>We provide effective consulting services to various companies across the globe in the aspect of system administration, and custom software development.</p>
                  </div>
                  <div className="service-catagory">
                    <ul>
                      <li><a href="#">Business Strategy </a></li>
                      <li><a href="#">Business Solutions</a></li>
                      <li><a href="#">HR Services</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="service-single-items d-flex mb-30">
                <div className="service-number">
                  <span>02</span>
                </div>
                <div className="service-content">
                  <div className="service-title">
                    <h2>What we do.</h2>
                  </div>
                  <div className="service-text">
                    <p>Valuesoft Info is known for its functional and technical deep expertise in providing Technology Consulting solutions and Services, across all Industries.</p>
                  </div>
                  <div className="service-catagory">
                    <ul>
                      <li><a href="#">Financial Services</a></li>
                      <li><a href="#">Project Management</a></li>
                      <li><a href="#">Application Development</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="service-single-items d-flex mb-30">
                <div className="service-number">
                  <span>03</span>
                </div>
                <div className="service-content">
                  <div className="service-title">
                    <h2>How we operate.</h2>
                  </div>
                  <div className="service-text">
                    <p>Whether bringing new amazing best products and services to market, or discovering new ways to make mature products for business.</p>
                  </div>
                  <div className="service-catagory">
                    <ul>
                      <li><a href="#">Industry Expertise</a></li>
                      <li><a href="#">Consulting Services</a></li>
                      <li><a href="#">Digital Technology</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choose-us-section pt-75 pb-70" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/ch-bg.jpg'})`
      }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="choose-us-thumb">
                <img src={process.env.PUBLIC_URL + '/images//ch1.png'} alt="ValueSoft_social_media" />

              </div>
            </div>
            <div className="col-md-6">
              <div className="choose-us-content-section pl-35">
                <div className="section-head-container">
                  <div className="section-head">
                    <h5> + Why Choose Us</h5>
                    <h3 className="text-white">Some Of the reasons, For Choosing Our  <span className="primary-orange "> Solutions</span></h3>

                    <p> When choosing an ERP reseller, we know you have a lot of companies to pick from. And it’s a big decision. One that could determine the success of your implementation project and affect your businesses for years to come.</p>
                  </div>
                </div>
                <div className="choose-us-content-container">
                  <div className="single-content  pb-25  pt-35 d-flex">
                    <div className="content-single-icon">
                      <i className="flaticon-process"></i>
                    </div>
                    <div className="single-content-inner">
                      <h5 className="inner-content-title pb-15 text-white">We Listen</h5>
                      <p>We know how important IT needs are to everyday operations for all business. </p>
                    </div>
                  </div>
                  <div className="single-content  pb-25 d-flex">
                    <div className="content-single-icon">
                      <i className="flaticon-system"></i>
                    </div>
                    <div className="single-content-inner">
                      <h5 className="inner-content-title pb-15 text-white">We are innovative</h5>
                      <p>Every day our teams find themselves in environments where innovation is a requirement. Our customers are always looking for innovative ideas.</p>
                    </div>
                  </div>
                  <div className="single-content  pb-25 d-flex">
                    <div className="content-single-icon">
                      <i className="flaticon-next-1"></i>
                    </div>
                    <div className="single-content-inner">
                      <h5 className="inner-content-title pb-15 text-white">We are Global</h5>
                      <p>Our priority is to support you and are now available for you in USA, India, Australia, and Malaysia.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-section style-5 pt-80 pb-180" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/team-bg.jpg'})`
      }}>
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="section-head text-center pb-55">
						<h5>Expert Engineer</h5>
						<h2>Meet Our <span> Dedicated Team</span></h2>
						<span class="section-head-bar-2"></span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="single-team-member">
						<div class="team-member-thumb">
            <img src={process.env.PUBLIC_URL + '/images/tm1.png'} alt="ValueSoft Team" />
							
						</div>
						<div class="team-member-social-icon">
            <ul>
								<li><a href="#"><i class="bi bi-facebook"></i></a></li>
								
								<li><a href="#"><i class="bi bi-linkedin"></i></a></li>
							</ul>
						</div>
						<div class="team-member-intro-section">
							<h2>Angel Zara</h2>
							<p>Migration Agent</p>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="single-team-member">
						<div class="team-member-thumb">
            <img src={process.env.PUBLIC_URL + '/images/tm2.png'} alt="ValueSoft Team" />
						</div>
						<div class="team-member-social-icon">
						<ul>
								<li><a href="#"><i class="bi bi-facebook"></i></a></li>
								
								<li><a href="#"><i class="bi bi-linkedin"></i></a></li>
							</ul>
						</div>
						<div class="team-member-intro-section">
							<h2>Shene Watsan</h2>
							<p>Visa Director</p>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="single-team-member">
						<div class="team-member-thumb">
            <img src={process.env.PUBLIC_URL + '/images/tm3.png'} alt="ValueSoft Team" />
						</div>
						<div class="team-member-social-icon">
						<ul>
								<li><a href="#"><i class="bi bi-facebook"></i></a></li>
								
								<li><a href="#"><i class="bi bi-linkedin"></i></a></li>
							</ul>
						</div>
						<div class="team-member-intro-section">
							<h2>Fahmida Haque</h2>
							<p>Co-Founder</p>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="single-team-member">
						<div class="team-member-thumb">
            <img src={process.env.PUBLIC_URL + '/images/tm1.png'} alt="ValueSoft Team" />
						</div>
						<div class="team-member-social-icon">
							<ul>
								<li><a href="#"><i class="bi bi-facebook"></i></a></li>
								
								<li><a href="#"><i class="bi bi-linkedin"></i></a></li>
							</ul>
						</div>
						<div class="team-member-intro-section">
							<h2>Shene Watsan</h2>
							<p>Visa Director</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
      <div className="section-head  call-project style-2  pt-75 pb-60 mb-15" style={{
        background: `linear-gradient(360deg, rgba(255, 60, 0, 0.87), rgba(241, 141, 1, 0.87)), #f4f7fc url(${process.env.PUBLIC_URL + '/images/pd-sh.jpg'})`
      }}>

        <div className="container">
          <div className="row">
            <div className=" col-sm-12 col-md-12 col-lg-5">
              <div className="section-title">
                <h5 className="text-white">// Our Case Study</h5>
                <h3 className="text-white">Introduce Our Projects</h3>
              </div>
            </div>
            <div className=" col-sm-12 col-md-12 col-lg-7">
              <div className="section-head-content">
                <p className="pt-0 pb-0 text-white">Software development outsourcing is just a tool to achieve business goals. But there is no way to get worthwhile results without cooperation and trust between a client company.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <section className="val-clients">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h5 className="primary-black" data-aos="flip-up">OUR CLIENTs</h5>
                        </div>
                        <div className="col-md-9">

                            <h3 className="mb-7 pprimary-black"  data-aos="fade-up"> <span className="primary-orange">We design  </span>inspring brands & online experiences for forward-thinking clients. </h3>
                           
                        </div>

                    </div>

                    <div className="row mt-5">
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/yash-technologies-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/aq4-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/bandgi-technologies-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/byteridge-logo-white.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/cigniti-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/covalensedigital-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/cybertech-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/deloitte-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/HCL-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/hitachi-Vantara.jpeg'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/flender-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/grocery-factory-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/siemens-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/wipro-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/tcs-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/tech-M-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        
                    </div>
                </div>
            </section>
    </ div>

  );
}

export default Company;