import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/hero.scss';


const scrollToTop = () => {
    window.scrollTo({
        top: 731,
        behavior: "smooth",
    });
  };

const Hero = () => {

    return (
            
            <section id="hero" className="val-hero val-home_sec" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/slider-bg3.png'})`
        }}>
            <div className="val-hero_body">
                <h1>  <span> HELPING </span>COMPANIES NAVIGATE CHANGES<span className=""></span></h1>
                <p data-aos="fade-up"
                    data-aos-duration="3000"> Our team’s professionalism and dedication allow us to remain competitive and deliver world-class services across the UAE, USA, and other countries.</p>
                    

            </div>
            <div className="val-hero_social"> 
                <ul>
                    <li><a href="https://www.instagram.com/valuesoftinfoservices/" target="_blank">    <img src={process.env.PUBLIC_URL + '/images/pintrest_icon.png'} alt="ValueSoft_Instagram" /> </a></li>
                    <li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank">    <img src={process.env.PUBLIC_URL + '/images/linkedin_icon.png'} alt="ValueSoft_linkedin" /> </a></li>

                    <li><a href="https://www.facebook.com/valuesoftinfoservices" target="_blank">    <img src={process.env.PUBLIC_URL + '/images/facebook_icon.png'} alt="ValueSoft_facebook" className="val_fb" /> </a></li>
                    <li>
                    
                        <a href="https://twitter.com/ValueSoft_info" target="_blank">    <img src={process.env.PUBLIC_URL + '/images/twitter_icon.png'} alt="ValueSoft_twitter" /> </a>
                        
                        </li>
                </ul>
            
                <Link to="#about" onClick={scrollToTop} className="scroll_img bounce  infinate"  >  <img src={process.env.PUBLIC_URL + '/images/val_scroll.png'} alt="ValueSoft_social_media" /> Scroll </Link>
      
            
            </div>
        </section>
    );
}

export default Hero;