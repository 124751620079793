import React from "react";




const Footer = () =>{
    return (
        <section className="footer bg-dark py-2">
            <footer className="text-center">
                <p className="mb-0 pb-0 text-center">Designed & Developed by ValueSoft Info | © 2020 Valuesoft Info Pvt Ltd. All rights reserved</p>
            </footer> 

        </section>

    );
}

export default Footer;