import React, { useState, useEffect } from 'react';

import { AboutNav } from '../Data/BreadcrumbData';

const Breadcrumb = ({url,bgimage,title }) => {


    return (
        <>

            <div className="breadcumb-area">


                <div className='bg-image'>
                    <img src={bgimage} />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcumb-content">



                                <h1>{title} </h1>
                                <ul>
                                    <li><a href="index.html">Home</a></li>
                                    <li><i className="bi bi-arrow-right-short"></i></li>
                                    <li>{title} </li>
                                </ul>


                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </>
    );

}


export default Breadcrumb;