import React from "react";
import ReactDOM from 'react-dom';
import "../scss/inner-page.scss"



const LeftColumn = ({title}) => {

    return (

        <>
            <div class="call-do-action">
                <div class="top-link">
                    <a href="#">{title}<i class="bi bi-arrow-right-short"></i></a>
                </div>
                <div class="single-pack d-flex">
                    <div class="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/download-file.png'} alt="ValueSoft_services" />
                    </div>
                    <div class="pack-content">
                        <div class="pack-title"><h5>Organization Profile</h5></div>
                        <div class="get-pack"><a href="/images/CP_VSI_.pdf">Download PDF</a></div>
                    </div>
                </div>

                <div class="single-pack d-flex">
                    <div class="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/value.png'} alt="ValueSoft_services" />
                    </div>
                    <div class="pack-content">
                        <div class="pack-title"><h5>SALES QUERIES</h5></div>
                        <div class="get-pack"><a href="mailto:sales@valuesoftinfo.com">Mail Us..!</a></div>
                    </div>
                </div>

                <div class="cda-content text-left">
                    <h4>Contact Us</h4>
                    <h3><a href="mailto:sales@valuesoftinfo.com"> <i class="bi bi-envelope-open"></i> sales@valuesoftinfo.com </a></h3>
                    <h3><a href="tel:080-43729615"><i class="bi bi-telephone-plus"></i> 080-43729615 </a></h3>
                    <h3><i class="bi bi-geo-alt"></i> USA(Irving), UAE(Dubai), Malaysia(kuala Lumpur), India(Bengaluru, Hyderabad, Pune)</h3>
                    <ul class="social">
                        <li class="active" ><a href="https://www.facebook.com/valuesoftinfo/"  target="_blank"><i class="bi bi-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ValueSoft_info"  target="_blank"><i class="bi bi-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank"><i class="bi bi-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/valuesoftinfo"  target="_blank"><i class="bi bi-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </>

    );
}

export default LeftColumn;