import React from "react";
import '../scss/IndustryExper.scss';
import { Routes, Route, Outlet, Link, NavLink } from "react-router-dom";


const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };
const IndustryExper = () => {
    return (
        <section className="val-industry val-home_sec" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/industry-exp_bg.png'})`
        }}>


            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 offset-0 ">
                            <div className="val-industry_content">
                                <h5  className="mb-5"  data-aos="flip-down">BUSINESS EXPERTISE</h5>
                                    <h2 className="mb-4" data-aos="fade-up">INDUSTRY EXPERTISE <span class="primary-orange"> SIMPLE...</span></h2>
                                    <p className="mb-5" data-aos="fade-up">We understand each industry has its specific business processes and success factors..</p>
                                    <Link  onClick={scrollToTop} to="/services"  className="secondary_cta" data-aos="fade-right">KNOW MORE    <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </Link>
                                   
                                 </div>
                    </div>

                </div>
            </div>


        </section>
    );
}

export default IndustryExper;