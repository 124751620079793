import React from "react";
import '../scss/about.scss';

import Company from "../InnerPage/Company";
import Products from "../InnerPage/Products";
import { Routes, Route, Outlet, Link, NavLink } from "react-router-dom";


  
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

const About = () => {
    return (

        <section id="about" className="val-about val-home_sec" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/about_bg.png'})`
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h5 data-aos="flip-up">ABOUT VALUESOFT Info</h5>
                    </div>
                    <div className="col-md-8">
                        <div className="val-about_right">
                            <h2 className="mb-4" data-aos="fade-up"> 15 YEARS OF EXPERIENCE <span className="primary-orange">.</span></h2>
                            <p className="mb-8" data-aos="fade-up">Valuesoft Info, is a global consulting and technology Services Company dedicated to acclerating the client's business with Microsoft technologies and ERP by delivering effective services and solutions. </p>
                            <p className="mb-6" data-aos="fade-up"><span className="primary-orange"> Vision </span>: To be the most admired company by employees, partners, clients, and stakeholders<br></br>
                            <span className="primary-orange"> Mission </span>: To provide simple IT solutions across complex business needs, ensuring commitment and quality processes through 4i global delivery framework  </p>
                            <Link onClick={scrollToTop}  to="/our-company" className="secondary_cta" > KNOW MORE    <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </Link>
                            {/* <button onClick={() => {history.push("/home")}} > </ button> */}
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}


export default About;