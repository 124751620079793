import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import { BusinessNav } from '../Data/BreadcrumbData'
import { BusinessAD } from '../Data/ArticleData'
import {BusinessStrategy } from '../Data/LeftColmnsData'
import { BusinessSolutionsArticle } from '../Data/ArticleFeatureData';
import LeftColumn from './Left-common';


const BusinessSolutions = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...BusinessNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...BusinessStrategy} />

                            </div>
                            <div class="col-lg-8">
                                <Article {...BusinessAD} />

                                <ArticleFeature  {...BusinessSolutionsArticle} />

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
export default BusinessSolutions;