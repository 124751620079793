import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { BusinessIntelligenceNav } from '../Data/BreadcrumbData'
import { BusinessIntelligenceAD } from '../Data/ArticleData'
import { BusinessIntelligenceArticle } from '../Data/ArticleFeatureData';
import {BITitle } from '../Data/LeftColmnsData'

const BusinessIntelligencePage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...BusinessIntelligenceNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn  {...BITitle}/>

                            </div>
                            <div class="col-lg-8">
                                <Article {...BusinessIntelligenceAD} />

                                <ArticleFeature  {...BusinessIntelligenceArticle} />

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default BusinessIntelligencePage;