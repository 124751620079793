import HeaderNav from './components/HomePage/Header';
import Home from './components/Home';
import Footer from './components/HomePage/Footer';

import AOS from 'aos';
import $ from "jquery";
import React, { useEffect } from 'react';
import './App.scss';
import './components/scss/varibles.scss';
import Rooting from './Router';

const refreshPage = () => {
  // window.location.reload();
}

class App extends React.Component {
  componentDidMount() {
    // Initialize AOS animations
    AOS.init();

    // Detect screen size and disable AOS on mobile devices
    const isMobile = window.innerWidth <= 767;
    const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;
    const isDesktop = window.innerWidth >= 1025;

    if (isMobile) {
      AOS.init({ disable: true });  // Disable AOS on mobile
    } else {
      AOS.init({ disable: false }); // Enable AOS on larger devices
    }

    // Example logic for different device types
    if (isTablet) {
      console.log('Tablet detected');
      // You can add tablet-specific behavior here
    }

    if (isDesktop) {
      console.log('Desktop detected');
      // You can add desktop-specific behavior here
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderNav />
        <Rooting />
        <Footer />
      </div>
    );
  }
}

export default App;
