import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { itConsultingNav } from '../Data/BreadcrumbData'
import { itConsultingAD } from '../Data/ArticleData'
import { itConsultingArticle } from '../Data/ArticleFeatureData';
import {ITTitle } from '../Data/LeftColmnsData'

const ItConsultingPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...itConsultingNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...ITTitle}/>

                            </div>
                            <div class="col-lg-8">
                                <Article {...itConsultingAD} />

                                <ArticleFeature  {...itConsultingArticle} />

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default ItConsultingPage;