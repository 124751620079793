import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { HrSolutionsNav } from '../Data/BreadcrumbData'
import { HrSolutionsAD } from '../Data/ArticleData'
import { HrSolutionsArticle } from '../Data/ArticleFeatureData';
import {HRTitle } from '../Data/LeftColmnsData'

const HrSolutionsPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...HrSolutionsNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...HRTitle} />

                            </div>
                            <div class="col-lg-8">
                                <Article {...HrSolutionsAD} />

                                <ArticleFeature  {...HrSolutionsArticle} />

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default HrSolutionsPage;