import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { ProcurementNav } from '../Data/BreadcrumbData'
import { ProcurementAD } from '../Data/ArticleData'
import { ProcurementArticle } from '../Data/ArticleFeatureData';
import {PSTitle } from '../Data/LeftColmnsData'

const ProcurementPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...ProcurementNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...PSTitle}/>

                            </div>
                            <div class="col-lg-8">
                                <Article {...ProcurementAD} />

                                <ArticleFeature  {...ProcurementArticle} />

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default ProcurementPage;