
import React from 'react';

import Hero from './HomePage/Hero';
import About from './HomePage/About';
import IndustryExper from './HomePage/IndustryExper';
import Portfolio from './HomePage/Portfolio';
import ContactForm from './HomePage/ContactForm';

import Services from './HomePage/Services';



const Home = () => {

    return (
        <>
           
            <Hero />
            <About />
            <Services />
            <IndustryExper />
            <Portfolio />
            <ContactForm />
          
 

        </>
    );

}


export default Home;