
export const AboutNav = {
    "id": 1,
    "url": "our-company",
    "title": "About Us",
    "bgimage": "/images/our-company.jpg"
};
export const serviceNav = {
    "id": 1,
    "url": "Services",
    "title": "Our Services",
    "bgimage": "/images/innerpage/image-asset(1)-min.jpg"
};

export const BusinessNav = {
    "id": 2,
    "url": "Business Strategy & Solutions",
    "title": "Business Strategy & Solutions",
    "bgimage": "/images/innerpage/image-asset(9)-min.jpg"
};
export const itConsultingNav = {
    "id": 2,
    "url": "IT Consulting",
    "title": "IT Consulting",
    "bgimage": "/images/innerpage/image-asset(13).jpg"
};

export const HrSolutionsNav = {
    "id": 2,
    "url": "  Human Resources Services",
    "title": "  Human Resources Services",
    "bgimage": "/images/innerpage/image-asset(8).jpg"
};

export const ProcurementNav = {
    "id": 2,
    "url": " Procurement & Supply Chain",
    "title": " Procurement & Supply Chain",
    "bgimage": "/images/innerpage/image-asset(13).jpg"
};

export const CorporateTrainingtNav = {
    "id": 2,
    "url": " Corporate Training",
    "title": " Corporate Training",
    "bgimage": "/images/innerpage/image-asset(7).jpg"
};

export const StaffingAugmentationNav = {
    "id": 2,
    "url": " Staffing Augmentation",
    "title": " Staffing Augmentation",
    "bgimage": "/images/innerpage/image-asset(14).jpg"
};

export const OutsourcingNav = {
    "id": 1,
    "url": " Outsourcing",
    "title": " Outsourcing",
    "bgimage": "/images/innerpage/image-asset(4)-min.jpg"
};

export const BusinessIntelligenceNav = {
    "id": 1,
    "url": " Business Intelligence",
    "title": "Business Intelligence",
    "bgimage": "/images/innerpage/image-asset(6).jpg"
};
export const DAManagmentNav = {
    "id": 1,
    "url": "Digital Asset Management",
    "title": "Digital Asset Management",
    "bgimage": "/images/innerpage/image-asset(11).jpg"
};

export const ArVrNav = {
    "id": 1,
    "url": "Ar VR",
    "title": "Ar VR",
    "bgimage": "/images/innerpage/image-asset(3).jpg"
};



export const M365BD = {
    "id": 1,
    "url": "Microsoft 365",
    "title": "Microsoft 365",
    "bgimage": "/images/innerpage/image-asset(2).jpeg"
};


export const ContactNav = {
    "id": 1,
    "url": "Get In Touch",
    "title": "Get In Touch",
    "bgimage": "/images/innerpage/image-asset(10).jpg"
};

