import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { OutsourcingNav } from '../Data/BreadcrumbData'
import { OutsourcingAD } from '../Data/ArticleData'
import { OutsourcingArticle } from '../Data/ArticleFeatureData';
import {OTitle } from '../Data/LeftColmnsData'

const OutsourcingPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...OutsourcingNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...OTitle} />

                            </div>
                            <div class="col-lg-8">
                                <Article {...OutsourcingAD} />

                                <div class="ap-section-content">
                                    <div class="overview-content">
                                        <h2 class="overview-title"> Our Outsourcing Services:</h2>
                                        <div class="row">
                                        <div class="col-md-12"> 
                                        <ul class="list-style-bullet">
                                            <li>IT Outsourcing: Boost your tech capabilities with our expert IT teams, providing everything from infrastructure management to software development.</li>
                                            <li>
                                            	Business Process Outsourcing (BPO): Streamline essential operations like customer support, data management, and back-office functions while reducing overhead costs.
                                            </li>

                                            <li>
                                            	Managed Services: Gain access to a full suite of managed services, from monitoring and maintenance to proactive IT support, ensuring smooth, uninterrupted business operations.
                                            </li>

                                            <li>
                                            	Offshore Outsourcing: Maximize efficiency by outsourcing key tasks to our global team of professionals, delivering high-quality results at competitive rates.
                                            </li>
                                            <li>
                                            	Custom Outsourcing Solutions: Tailor your outsourcing strategy with a bespoke solution that fits your unique business goals, whether you need to scale fast or improve operational efficiency.
                                            </li>
                                        </ul>
                                        </div>
                                        </div>
                                          <div class="row">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="overview-thumb mb-30">
                                                    <img src="/images/innerpage/outsourcing-1.png"></img>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div class="ap-content-inner d-flex">
                                                    <div class="ap-content-icon">
                                                        <i class="bi bi-check-circle"></i>
                                                    </div>
                                                <div class="ap-content-text ml-20"> Why Choose Valuesoft Info?</div>
                                            </div>
                                            <div class="ap-content-inner d-flex mt-10">{/*
                                                <div class="ap-content-icon">
                                                    <i class="bi bi-check-circle"></i>
                                                </div>*/}
                                                <div class="row">
                                        <div class="col-md-12"> 
                                        <ul class="list-style-bullet">
                                            <li>	Cost-Efficient: Outsource strategically to reduce costs while maintaining high standards of quality.</li>
                                            <li>
                                            	Expertise on Demand: Gain instant access to specialized talent in various fields, from IT to customer service.
                                            </li>

                                            <li>
                                            	Focus on Core Competencies: Free your internal teams to focus on growth, while we care for your operational needs.
                                            </li>

                                            <li>
                                            	Global Reach, Local Understanding: Leverage the best of global talent with the advantage of local market knowledge.
                                            </li>

                                            
                                        </ul>
                                        </div>
                                        </div>
                                                </div></div></div><p class="pt-15 pb-25">Partner with Valuesoft Info and unlock the power of outsourcing to accelerate your business success!</p></div></div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default OutsourcingPage;