import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';

import { ArVrNav } from '../Data/BreadcrumbData'
import { ArVrAD } from '../Data/ArticleData'
import { ARTitle } from '../Data/LeftColmnsData'
import "../scss/inner-page.scss";

const LeftColumn = ({ title }) => {
    return (
        <>
            <div className="call-do-action">
                <div className="top-link">
                    <a href="#">{title}<i className="bi bi-arrow-right-short"></i></a>
                </div>
                <div className="single-pack d-flex">
                    <div className="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/download-file.png'} alt="ValueSoft_services" />
                    </div>
                    <div className="pack-content">
                        <div className="pack-title"><h5>Organization Profile</h5></div>

                        <div className="get-pack"><a href="/images/CP_VSI_.pdf">Download PDF</a></div>
                    </div>
                </div>
                <div className="single-pack d-flex">
                    <div className="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/worldwide.png'} alt="ValueSoft_services" />
                    </div>
                    <div className="pack-content">
                        <div className="pack-title"><h5>About Our Partner</h5></div>

                        <div className="get-pack"><a href="https://tiltlabs.io/">"Tiltlabs.io - Know More"</a></div>
                    </div>
                </div>

                <div className="single-pack d-flex">
                    <div className="pack-icon">
                        <img src={process.env.PUBLIC_URL + '/images/value.png'} alt="ValueSoft_services" />
                    </div>
                    <div className="pack-content">
                        <div className="pack-title"><h5>SALES QUERIES</h5></div>
                        <div className="get-pack"><a href="mailto:sales@valuesoftinfo.com">Mail Us..!</a></div>
                    </div>
                </div>

                <div className="cda-content text-left">
                    <h4>Contact Us</h4>
                    <h3><a href="mailto:sales@valuesoftinfo.com"> <i className="bi bi-envelope-open"></i> sales@valuesoftinfo.com </a></h3>
                    <h3><a href="tel:080-43729615"><i className="bi bi-telephone-plus"></i> 080-43729615 </a></h3>
                    <h3><i className="bi bi-geo-alt"></i> USA(Irving), UAE(Dubai), Malaysia(kuala Lumpur), India(Bengaluru, Hyderabad, Pune)</h3>
                    <ul className="social">
                        <li className="active"><a href="https://www.facebook.com/valuesoftinfo/" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ValueSoft_info" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/valuesoftinfo" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

const ArVrPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...ArVrNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...ARTitle} />

                            </div>
                            <div class="col-lg-8">
                                <Article {...ArVrAD} />

                                <div class="ap-section-content">
                                    <div class="overview-content">
                                        <p> By integrating AR, VR, MR, and data intelligence, Valuesoft Info provides solutions that optimize each stage of product development, from design and testing to production. </p>
                                        <h2 class="overview-title"> Key benefits include: </h2>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ul class="list-style-bullet">
                                                    <li>Enhanced Product Design: AR and VR offer immersive, interactive design experiences, enabling designers to create and refine product prototypes in a realistic environment. </li>
                                                    <li>Accelerated Product Testing: MR supports realistic testing environments, reducing dependency on physical prototypes, speeding up testing, and refining accuracy. </li>
                                                    <li>Increased Customer Engagement: AR and VR allow for captivating product demonstrations, letting customers interact with products in dynamic, memorable ways. </li>
                                                    <li>Data-Driven Insights: Advanced data intelligence supplies deep insights into customer behaviour and preferences, fuelling strategic, data-driven decisions throughout product realization. </li>
                                                    <li>
                                                        Streamlined Manufacturing: AR and VR streamline manufacturing by enhancing process optimization, quality control, and reducing production costs.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="overview-thumb mb-30">
                                                    <img src="/images/innerpage/ar-1.png"></img>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div class="ap-content-inner d-flex">
                                                    <div class="ap-content-icon">
                                                        <i class="bi bi-check-circle"></i>
                                                    </div>
                                                    <div class="ap-content-text ml-20"> Why Choose Valuesoft Info?</div>
                                                </div>
                                                <div class="ap-content-inner d-flex mt-10">{/*
                                                <div class="ap-content-icon">
                                                    <i class="bi bi-check-circle"></i>
                                                </div>*/}
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <ul class="list-style-bullet">
                                                                <li>	Innovative AI Expertise: With the combined power of Tiltlabs and Valuesoft Info, we bring top-tier AI knowledge and experience to your business. </li>
                                                                <li>
                                                                    Tailored Solutions: We provide AI solutions that are custom fit to your business needs, ensuring maximum impact and efficiency.
                                                                </li>

                                                                <li>
                                                                    Cutting-Edge Technology: Access the latest AI tools and technologies to drive automation, innovation, and business growth.
                                                                </li>

                                                                <li>
                                                                    End-to-End Support: From consulting and development to implementation and ongoing support, we’re with you every step of the way.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="pt-15 pb-15">Accelerate your digital transformation with AI solutions from Valuesoft Info—your trusted partner for the future of innovation!</p>
                                        <p>   Trust Valuesoft Info and Tiltlabs to unlock the full potential of AI for your business! </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default ArVrPage;